import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ImageEditor.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import imageCompression from 'browser-image-compression';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Header from './Header';
import logo from './logonew.png';
import { ClipLoader } from 'react-spinners';
import { handleLogin } from './firebaseLogin';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { FaArrowUp } from 'react-icons/fa';

import { useDispatch } from 'react-redux'; 
import { useSelector } from 'react-redux';
import { toPng } from 'html-to-image';
import { FaStar } from 'react-icons/fa';
import { format } from "date-fns";
import { FaDiamond } from "react-icons/fa6";
import { FaMagic } from "react-icons/fa";
import Popup from './Popup'; 
import PopUpAd from './PopUpAd'; 
import { IoDiamond } from "react-icons/io5";
import { LuRefreshCcw } from "react-icons/lu";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import MemeFeed from './MemeFeed';


import { getFonts } from './getFonts';
import { ReactComponent as OpenAILogo } from './open-ai-logo.svg'; 
import { FaBoltLightning } from "react-icons/fa6";
import { RiOpenaiFill } from "react-icons/ri";
import { SiOpenai } from "react-icons/si";
import { SiLetterS } from 'react-icons/fa';
import { BiLogoStripe } from "react-icons/bi";
import { RiRobot3Fill } from "react-icons/ri";
import { GrManual } from "react-icons/gr";
import { FaFont } from "react-icons/fa";
import { MdFontDownload } from "react-icons/md";
import { PiSpeakerHighFill } from "react-icons/pi";
import Faq from './Faq';
import { setDoc, collection, addDoc } from "firebase/firestore"; 
import { FaFlag } from 'react-icons/fa';





const getArrayFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  };
  const setArrayToLocalStorage = (key, array) => {
    localStorage.setItem(key, JSON.stringify(array));
  };
    
//
function ImageEditor({setShowFooter}) {
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [memeText, setMemeText] = useState(null);
  const [memeTextAI, setMemeTextAI] = useState('');
  const [imageDescriptionAI, setImageDescriptionAI] = useState(''); // For models requiring an additional input
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editedImageUrl, setEditedImageUrl] = useState(null);
  const [editedImageUrl2, setEditedImageUrl2] = useState(null);
  const [selectedModel, setSelectedModel] = useState('free'); 
  const [generatedModel, setGeneratedModel] = useState(selectedModel); 
  const [selectedTone, setSelectedTone] = useState('Positive');// New state for selected model
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const memeTextRef = useRef(null);
  const imageDescriptionAIRef = useRef(null);
  const [items, setItems] = useState(() => getArrayFromLocalStorage('context'));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupAdOpen, setIsPopupAdOpen] = useState(false);
  
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [reportReason, setReportReason] = useState("");

  const memeContainerRef = useRef(null); // Ref for the meme container
  const [user, setUser] = useState(false);
  const credits = useSelector(state => state);
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paidBoolean, setPaidBoolean] = useState(false)
  const [selectedCredits, setSelectedCredits] = useState(50);
  const [price, setPrice] = useState('0.40');
  const [selectedMode, setSelectedMode] = useState('AI');
  const [imageDescription, setImageDescription] = useState(''); 
  const [selectedMemeFont, setSelectedMemeFont] = useState('Arial');// New state for mode selection
  const [error, setError] = useState(null);
  const [fonts, setFonts] = useState([]);
  const [removeWatermark, setRemoveWatermark] = useState(false);
  const [imagePopUp, setImagePopUp] = useState(null);
  const [memeTextPopUp, setMemeTextPopUp] = useState(null);
  const [startBoolean, setStartBoolean] = useState(true);
  const [storedTimeMillis, setStoredTimeMillis] = useState(null);
  const [isGreaterThanOneDay, setIsGreaterThanOneDay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const images = [
    'preview1.png',
    'preview2.png',
    'preview3.png',
    'preview4.png',
    "preview5.png",
    "preview6.png",
];

const modelNames = [
  { name: 'Free', gems: 0, modelId:"free" },
  { name: 'Advanced', gems: 5, modelId:"sdxl" },
  { name: 'Ultimate 1', gems: 10, modelId:"dall-e-3"  },
  { name: 'Ultimate 2', gems: 10, modelId:"sd-3"  }
];

const modes = [
  'AI',
  'Manual',
];

const tones = [
    'Funny',
    'Positive',
    'Sarcastic',
    'Happy',
    'Sad',
    'Angry',
    'Motivating',
    'Playful',
    'Romantic',
    'Kind',
    'Bold',
    'Supportive',
    'Calm'
];

const firebaseConfig = {
  apiKey: "AIzaSyDlcz8dc5wvVWMK8WDRPlq3Y7pYRwgGIAM",
  authDomain: "auth.memehaha.lol",
  projectId: "bitnews-b750f",
  storageBucket: "bitnews-b750f.appspot.com",
  messagingSenderId: "907931117046",
  appId: "1:907931117046:web:047fd767ae18b124ac93d7",
  measurementId: "G-NNE8DE06XP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Firebase Auth instance
const db = getFirestore(app);
 
useEffect(() => {
  setShowFooter(false)
    const fetchFonts = async () => {
      try {
        const detectedFonts = getFonts();
        setFonts(detectedFonts);
      } catch (err) {
        setError('Error fetching fonts');
      } finally {
        setLoading(false);
      }
    };

    fetchFonts();
  }, []);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100); // Show button after scrolling 100px
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    // Retrieve the stored time from local storage
    const storedTime = localStorage.getItem('currentTimeMillis');

    // Set the stored time in the state if it exists
    if (storedTime) {
      const storedTimeInMillis = parseInt(storedTime, 10);
      setStoredTimeMillis(storedTimeInMillis);

      // Calculate the difference between the current time and stored time
      const currentTimeMillis = Date.now();
      const timeDifference = currentTimeMillis - storedTimeInMillis;

      // Check if the difference is greater than 1 day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      const oneDayInMillis =  6 * 60 * 60 * 1000;
      setIsGreaterThanOneDay(timeDifference > oneDayInMillis);

      if(timeDifference > oneDayInMillis){
        console.log("isGreaterThanOneDay",isGreaterThanOneDay)
        localStorage.setItem('currentTimeMillis', currentTimeMillis.toString());
      }
     
      
    }else{
      const currentTimeMillis = Date.now();

      // Store the current time in local storage
      localStorage.setItem('currentTimeMillis', currentTimeMillis.toString());
  
      // Optionally, you can log the current time in milliseconds to the console
      console.log('Current time in milliseconds stored in local storage:', currentTimeMillis);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setImage(Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
    }
  });

  useEffect(() => {

    setArrayToLocalStorage('context', items);
  }, [items]);




  useEffect(() => {


    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [images.length]);

  const fetchCredits1 = async (uid) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        dispatch({ type: 'SET_CREDITS', payload: docSnap.data().credits });
        if(docSnap.data().credits==0){
          setRemoveWatermark(false)
        }
        
        return docSnap.data().credits;
      } else {
      
        return 0;
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
      return 0;
    }
  };
  const togglePopup = () => {

    setIsPopupOpen(!isPopupOpen);
  };


  const togglePopupAd = () => {
    console.log(isPopupAdOpen)
    setIsPopupAdOpen(!isPopupAdOpen);
  };


  useEffect(() => {
    if (selectedMode === 'Manual') {
      if (memeTextRef.current) {
        memeTextRef.current.focus();
      }

      if (imageDescriptionAIRef.current) {
        imageDescriptionAIRef.current.focus();
      }
    }
    else{
        if (inputRef.current) {
            inputRef.current.focus();
          }
    }

  }, [selectedMode]);


  useEffect(() => {
    const fetchCredits = async (uid) => {
      try {
        const db = getFirestore();
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          dispatch({ type: 'SET_CREDITS', payload: docSnap.data().credits });
          setPaidBoolean(docSnap.data().paidBoolean)
          return docSnap.data().credits;
        } else {
          return 0;
        }
      } catch (error) {
        console.error('Error fetching credits:', error);
        return 0;
      }
    };

    onAuthStateChanged(getAuth(), async (authUser) => {
      if (!authUser) {
        // User is signed out.
        setUser(false);
      } else {
        // User is signed in.
        setUser(true);

        const uid = getAuth().currentUser.uid;

        setTimeout(function(){
          fetchCredits(uid);
        }, 2000);
      }
    });
  }, []);

  const handleReportSubmit = async () => {
  
    try {
        // Calculate dimensions and scaling for optimization
   
  
        // Generate optimized PNG
  
        // Save report data to Firestore
        await addDoc(collection(db, "reportedMemes"), {
          imageDescription: imageDescription,
          text: memeText,
          reason: reportReason,
          reportedAt: serverTimestamp(),
        });
  
        setShowReportPopup(false);
        setReportReason("");
        toast.success("Report submitted successfully!", {
          position: "top-right",
          autoClose: 3000, // 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
  
  
    } catch (error) {
      console.error("Error reporting content:", error);
      toast.error("Failed to submit the report. Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  

  const handleEditImage = async () => {
    setLoading(true);
    setProgress(0);

    let combinedPrompt = prompt; // Default to AI mode prompt

  if (selectedMode === 'Manual') {
    combinedPrompt = `Use this same value for meme_content: '${memeTextAI}', Use this same value for image_description:'${imageDescriptionAI}'`;
  }

  if(selectedTone!="Default"&&selectedMode != 'Manual'){
    combinedPrompt=`Meme context: ${combinedPrompt}. Make it sound ${selectedTone}`
  }
    var userCredits = 0;
    if (!user && selectedModel!="free" ) {
      const isLoggedIn = await handleLogin();
      setUser(isLoggedIn);

      if (!isLoggedIn) {
        return;
      }


      // Fetch the user's credits after logging in
      const uid = getAuth().currentUser.uid;
      userCredits = await fetchCredits1(uid);
      console.log(selectedModel)

      if (userCredits <= 0 && selectedModel!="free") {
        // If user's credits are 0, open Stripe checkout flow
        togglePopup()
        setLoading(false); // Submit the form to trigger the checkout flow
        return;
      }
    }

    if (!combinedPrompt) return;

    if (credits <= 0 && userCredits <= 0 && selectedModel!="free" ) {
      // If user's credits are 0, open Stripe checkout flow
        togglePopup()// Submit the form to trigger the checkout flow
        setLoading(false);
      return;
    }

    // if(selectedModel=="free"){
    //   console.log("I am here", isPopupAdOpen)
    //   togglePopupAd()
    // }



    // if(startBoolean){
    //   console.log("isGreaterThanOneDay2", isGreaterThanOneDay)
    //   if(isGreaterThanOneDay&&selectedModel=="free"){
    //     handleEditImagePopup()
    //     setStartBoolean(false)
    //   }
    // }
    // else{
     
    // }
   

  
    try {
      const formData = new FormData();
   
      formData.append('prompt', combinedPrompt);
      formData.append('tone', selectedTone);
      formData.append('model', selectedModel);
      formData.append('mode',selectedMode );
      formData.append('localContext', JSON.stringify(items));
      var editImageURL="";


      if(getAuth().currentUser){
        editImageURL=`https://easyedit.xyz:3000/generateMeme?uid=${getAuth().currentUser.uid}`;
      }else{
        editImageURL=`https://easyedit.xyz:3000/generateMeme?uid=default`;
      }
     

      const updateProgress = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          return newProgress >= 95 ? 95 : newProgress;
        });
      }, 400);

      axios.post(editImageURL, formData)
        .then(async (response) => {
          clearInterval(updateProgress);
          setProgress(100);

          if(response.data.error){
            toast.error(response.data.error);
            return
          }
         
          if(selectedModel=="free"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)
         

          }
          else if(selectedModel=="sdxl-l"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)
    
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sdxl"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)
           
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sd-3"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)

            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sd"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)
          
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="dall-e-3"){
            const editedImageBlob1 = await base64ToBlob(response.data.openAI);
            const editedImageUrl1 = URL.createObjectURL(editedImageBlob1);
            setImage2(editedImageUrl1);
            setEditedImageUrl2(editedImageUrl1);
            setMemeText(response.data.memeText);
            setImageDescription(response.data.imageDescription)
            fetchCredits1(getAuth().currentUser.uid);
          }


          setGeneratedModel(selectedModel)

          if(!getAuth().currentUser){
            
            var assistantMessage = ""

            if(response.data.memeText){
                assistantMessage=response.data.memeText
            }

            addItems([
                { role: "user", content: JSON.stringify(`${prompt} Generate another one`) },
                { role: "assistant" ,content: JSON.stringify(assistantMessage) },
              ])
            setArrayToLocalStorage("context", items)

         
          }
        })
        .catch(error => {
          clearInterval(updateProgress);
          console.error('Error:', error);
          toast.error('An error occurred. Please try again.');
          setProgress(0);
       
        })
        .finally(() => {
          setLoading(false);
          handleScrollToTop()
          logEvent(getAnalytics(), 'meme_generated', {
            model:generatedModel,
            selectedMode:selectedMode,
            time: new Date().toISOString()
        });

        logEvent(getAnalytics(), `${generatedModel}`, {
          selectedMode:selectedMode,
          time: new Date().toISOString()
      });

        });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
      setLoading(false);
  
    }
  };

  const handleEditImagePopup = async () => {
    let combinedPrompt = prompt; // Default to AI mode prompt

  if (selectedMode === 'Manual') {
    combinedPrompt = `Use this same value for meme_content: '${memeTextAI}', Use this same value for image_description:'${imageDescriptionAI}'`;
  }

  if(selectedTone!="Default"&&selectedMode != 'Manual'){
    combinedPrompt=`Meme context: ${combinedPrompt}. Make it sound ${selectedTone}`
  }

    if (!combinedPrompt) return;

   
    try {
      const formData = new FormData();

      
      var selectedModelPopup="dall-e-3"

      formData.append('prompt', combinedPrompt);
      formData.append('tone', selectedTone);
      formData.append('model', selectedModelPopup);
      formData.append('mode',selectedMode );
      formData.append('localContext', JSON.stringify(items));
      var editImageURL="";

      console.log(" I ammm here")


      if(getAuth().currentUser){
        editImageURL=`https://easyedit.xyz:3000/generateMeme?uid=${getAuth().currentUser.uid}`;
      }else{
        editImageURL=`https://easyedit.xyz:3000/generateMeme?uid=default`;
      }
     

      axios.post(editImageURL, formData)
        .then(async (response) => {

         
          if(selectedModelPopup=="free"){
            setImagePopUp(response.data.openAI);
            setMemeTextPopUp(response.data.memeText);


          }
          else if(selectedModelPopup=="sdxl-l"){
            setImagePopUp(response.data.openAI);
            setMemeTextPopUp(response.data.memeText);

          }
          else if(selectedModelPopup=="sdxl"){
            setImagePopUp(response.data.openAI);
            setMemeTextPopUp(response.data.memeText);
          }
          else if(selectedModelPopup=="sd-3"){
            setImagePopUp(response.data.openAI);
            setMemeTextPopUp(response.data.memeText);
          }
          else if(selectedModelPopup=="sd"){
            setImagePopUp(response.data.openAI);
            setMemeTextPopUp(response.data.memeText);
          }
          else if(selectedModelPopup=="dall-e-3"){
            const editedImageBlob1 = await base64ToBlob(response.data.openAI);
            const editedImageUrl1 = URL.createObjectURL(editedImageBlob1);
            setImagePopUp(editedImageUrl1);
            setMemeTextPopUp(response.data.memeText);
          }

        })
        .catch(error => {
       
        })
        .finally(() => {
      
        });
    } catch (error) {
  
    }
  };

  async function  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling
    });
}



const handleToggleChange = async (event) => {
  let userCredits = 0;

  console.log("true")

  // Check if user is logged in
  if (!user) {
    const isLoggedIn = await handleLogin();
    setUser(isLoggedIn);

    if (!isLoggedIn) {
      return;
    }

    // Fetch the user's credits after logging in
    const uid = getAuth().currentUser.uid;
    userCredits = await fetchCredits1(uid);
  }

  // Check if the user has enough credits
  if (credits <= 0 && userCredits <= 0) {
    togglePopup();
  } else {
    setRemoveWatermark(event.target.checked);
  }
};


  const handleChangeImage = async () => {
   
    var userCredits = 0;

    logEvent(getAnalytics(), 'change_image_clicked', {
        model:generatedModel,
        selectedMode:selectedMode,
        time: new Date().toISOString()
    });

    if (!user && selectedModel!="free" ) {
      const isLoggedIn = await handleLogin();
      setUser(isLoggedIn);

      if (!isLoggedIn) {
        return;
      }


      // Fetch the user's credits after logging in
      const uid = getAuth().currentUser.uid;
      userCredits = await fetchCredits1(uid);
      console.log(selectedModel)

      if (userCredits <= 0 && selectedModel!="free") {
        // If user's credits are 0, open Stripe checkout flow
        togglePopup()
        setLoading(false); // Submit the form to trigger the checkout flow
        return;
      }
    }

    if (credits <= 0 && userCredits <= 0 && selectedModel!="free" ) {
      // If user's credits are 0, open Stripe checkout flow
        togglePopup()

      // Submit the form to trigger the checkout flow

      return;
    }

    setLoadingImage(true);
    setProgress(0);

    try {
      const formData = new FormData();
   
      formData.append('imageDescription', imageDescription);
      formData.append('model', selectedModel);
      var editImageURL="";


      if(getAuth().currentUser){
        editImageURL=`https://easyedit.xyz:3000/changeImage?uid=${getAuth().currentUser.uid}`;
      }else{
        editImageURL=`https://easyedit.xyz:3000/changeImage?uid=default`;
      }
     

      const updateProgress = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          return newProgress >= 95 ? 95 : newProgress;
        });
      }, 400);

      axios.post(editImageURL, formData)
        .then(async (response) => {
          clearInterval(updateProgress);
          setProgress(100);

          if(response.data.error){
            toast.error(response.data.error);
            return
          }
         
          if(selectedModel=="free"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
       
          }
          else if(selectedModel=="sdxl-l"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
        
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sdxl"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);

            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sd-3"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
         
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="sd"){
            setImage2(response.data.openAI);
            setEditedImageUrl2(response.data.openAI);
       
            fetchCredits1(getAuth().currentUser.uid);
          }
          else if(selectedModel=="dall-e-3"){
            const editedImageBlob1 = await base64ToBlob(response.data.openAI);
            const editedImageUrl1 = URL.createObjectURL(editedImageBlob1);
            setImage2(editedImageUrl1);
            setEditedImageUrl2(editedImageUrl1);
            fetchCredits1(getAuth().currentUser.uid);
          }

        })
        .catch(error => {
          clearInterval(updateProgress);
          console.error('Error:', error);
          toast.error('An error occurred. Please try again.');
          setProgress(0);
        })
        .finally(() => {
        setLoadingImage(false);
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const addItems = (newItems) => {
    setItems((prevItems) => [...prevItems, ...newItems]);
  };
  
 

  async function base64ToBlob(base64String, contentType = 'application/octet-stream') {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
  const uploadImageToStorage = async (dataUrl) => {
    const storage = getStorage();
    const storageRef = ref(storage, `memes/${Date.now()}.png`);
  
    // Upload the image data URL to Firebase Storage
    await uploadString(storageRef, dataUrl, 'data_url');
  
    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);
  
    return downloadURL;
  };
  
  const handleDownloadImage = async () => {
    var editImageURL = "";
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  
    if (getAuth().currentUser) {
      editImageURL = `https://easyedit.xyz:3000/downloadImage?uid=${getAuth().currentUser.uid}&removeWatermark=${removeWatermark}`;
      axios.post(editImageURL)
        .then(async (response) => {
          fetchCredits1(getAuth().currentUser.uid);
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('An error occurred. Please try again.');
        })
        .finally(() => {
          setLoadingImage(false);
        });
    } else {
      editImageURL = `https://easyedit.xyz:3000/downloadImage?uid=default`;
    }
  
    if (memeContainerRef.current) {
      try {
        logEvent(getAnalytics(), 'meme_downloaded', {
          model: generatedModel,
          selectedMode: selectedMode,
          removeWatermark: removeWatermark,
          time: new Date().toISOString(),
        });
  
        const element = memeContainerRef.current;
        const { width, height } = element.getBoundingClientRect();
  
        const dataUrl = await toPng(element, {
          width,
          height,
          style: {
            fontFamily: window.getComputedStyle(element).fontFamily,
            fontSize: window.getComputedStyle(element).fontSize,
            fontWeight: window.getComputedStyle(element).fontWeight,
            color: window.getComputedStyle(element).color,
            textAlign: window.getComputedStyle(element).textAlign,
            padding: window.getComputedStyle(element).padding,
            margin: window.getComputedStyle(element).margin,
            lineHeight: window.getComputedStyle(element).lineHeight,
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          },
        });
  
        // Send the data URL to React Native
        // Ensure dataUrl is not null or undefined before posting the message
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          window.ReactNativeWebView.postMessage(dataUrl);
        } else {
          console.error("Error: ReactNativeWebView or postMessage is not available.");
        }
        

      } catch (error) {
        console.error('Error downloading image:', error);
        toast.error('Failed to download image.');
      }
    }
  };
  

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,          // Maximum size in MB
      maxWidthOrHeight: 1024, // Max width or height
      useWebWorker: true,    // Use web worker for the compression
    };

    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  };

  const formatDate = () => {
    const now = new Date();
    return format(now, "MMMM d, yyyy 'at' h:mm:ss a 'UTC'XXX");
  };

  async function compressImageFromURL(imageURL) {
    try {
      const response = await axios.get(imageURL, {
        responseType: 'arraybuffer'
      });

      const imageData = new Blob([response.data], { type: 'image/jpeg' });

      const compressedFile = await imageCompression(imageData, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      });

      return compressedFile;
    } catch (error) {
      console.error('Error compressing image from URL:', error.message);
      throw error;
    }
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
    }
  };

  const updatePrice = (credits, price) => {
    setSelectedCredits(credits);
    setPrice(price);

    const uid = getAuth().currentUser.uid;
    const actionUrl = `https://easyedit.xyz:3000/create-checkout-session?uid=${uid}&credits=${credits}&price=${price}`;
    
    const form = document.getElementById('checkout-form');
    form.setAttribute('action', actionUrl);
};

const preventRightClick = (e) => {
  e.preventDefault();
};


  return (
    <div className="App-ie">
      <Helmet>
        <title>AI Meme Generator</title>
        <meta name="description" content="Generate hilarious memes with our AI powered meme generator" />
      </Helmet>
      <ToastContainer />
      <script src="https://js.stripe.com/v3/"></script>
      <div className="meme-generation-container">
      <div className="image-container">
        {image2 && (
          <div className="image-preview" ref={memeContainerRef}>
           
           {memeText && <p className="meme-text" style={{ fontFamily: selectedMemeFont }}>{memeText}</p>}
           <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', margin: 0, padding: 0 }}>
  <img
    src={image2}
    alt="Uploaded"
    className="image"
    onContextMenu={preventRightClick}
    onMouseDown={preventRightClick}
    style={{ display: 'block', margin: 0, padding: 0 }}
  />
  {!removeWatermark && (
    <div
      style={{
        position: 'absolute',
        bottom: '10px', // 10px from the bottom
        right: '10px',  // 10px from the left
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0', // Remove any padding
        margin: '0', // Remove any margin
      }}
    >
      <p className="watermark-text" style={{
        fontFamily: selectedMemeFont,
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#ffffff', // Optional text shadow
        margin: 0,
        padding: 0,
        lineHeight: '1', // Ensure no extra line height
      }}>
        Memehaha.lol
      </p>
    </div>
  )}
</div>



            {(loading || loadingImage) && (
  <div className="loading-overlay">
    <ClipLoader color="#0056b3" loading={true} size={50} />
    <p>Generating... {progress}%</p>
  </div>
)}


          </div>

          
        )}
      
       {image2 && (
       <div>
       <button
         className="download-button"
         onClick={handleDownloadImage}
         disabled={isButtonDisabled}
       >
         {isButtonDisabled ? "Downloading..." : "Download"}
       </button>
 
       
     </div>
  )}
      </div>

      {image2 && (
  <button
    className="report-button"
    onClick={() => setShowReportPopup(true)}
    aria-label="Report"
    style={{
      background: 'none',
      border: 'none',
      padding: '0',
      cursor: 'pointer',
    }}
  >
    <FaFlag size={20} color="red" />
  </button>
)}
 {showReportPopup && (
  <div className="popup-overlay" style={{
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  }}>
    <div className="popup-content" style={{
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      width: '400px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
    }}>
      <h3 style={{
        marginBottom: '15px',
        fontSize: '20px',
        color: '#333',
      }}>Report Inappropriate Content</h3>
      <textarea
        placeholder="Enter the reason for reporting..."
        value={reportReason}
        onChange={(e) => setReportReason(e.target.value)}
        rows="5"
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          marginBottom: '15px',
          fontSize: '14px',
          resize: 'none',
        }}
      ></textarea>
      <div className="popup-actions" style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <button
          onClick={handleReportSubmit}
          className="submit-button"
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '10px 20px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#45a049')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#4CAF50')}
        >
          Submit Report
        </button>
        <button
          onClick={() => setShowReportPopup(false)}
          className="cancel-button"
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '10px 20px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#d32f2f')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#f44336')}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      <div>
        <div className="input-button-container">
          <div className="prompt-container">
            <div className="input-container">
              <div className="prompt-input-wrapper">
              {/* <h10 style={{ fontWeight : 'bold' }}>Select Tone</h10>
              <div className="model-selection-container" style={{  marginTop: '20px' , marginBottom:'30px'}}>
                
      <button 
        onClick={() => setSelectedTone('default')} 
        className={selectedTone === 'default' ? 'selected' : 'model-button'}
      >
        Default
      </button>
      <button 
        onClick={() => setSelectedTone('positive')} 
        className={selectedTone === 'positive' ? 'selected' : 'model-button'}
      >
        Positive
      </button>
    </div> */}
   
   {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
  <div style={{ fontWeight: 'bold', fontSize: '15px', color: '#fff' }}>
   Model
  </div>
  <select
  value={selectedModel}
  onChange={(e) => setSelectedModel(e.target.value)}
  className="font-dropdown no-outline"
>
  {modelNames.map(({ name, gems, modelId }) => (
    <option key={modelId} value={modelId}>
      {name} {gems > 0 && <span style={{ marginLeft: '10px' }}>({gems} gems)</span>}
    </option>
  ))}
</select>
</div> */}


<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
  <div style={{ fontWeight: 'bold', fontSize: '15px', color: '#fff' }}>
    Mode
  </div>

  <select
    value={selectedMode}
    onChange={(e) => setSelectedMode(e.target.value)}
    className="font-dropdown no-outline"
    style={{ marginLeft: 'auto' }} // Ensure select aligns to the right
  >
    {modes.map(mode => (
      <option key={mode} value={mode}>{mode}</option>
    ))}
  </select>
</div>



<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ fontWeight: 'bold', marginRight: '10px', fontSize: '15px', color: '#fff' }}>
      Font
    </div>
  </div>

  <select
    value={selectedMemeFont}
    onChange={(e) => setSelectedMemeFont(e.target.value)}
    className="font-dropdown no-outline"
  >
    {fonts.map(font => (
      <option key={font} value={font}>{font}</option>
    ))}
  </select>
</div>
{selectedMode === "AI" && (
  <div className="tone-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontWeight: 'bold', marginRight: '10px', fontSize: '15px', color: '#fff' }}>
     Mood
      </div>
    </div>
    
    <select
      value={selectedTone}
      onChange={(e) => setSelectedTone(e.target.value)}
      className="font-dropdown no-outline"
    >
      {tones.map(tone => (
        <option key={tone} value={tone}>{tone}</option>
      ))}
    </select>
  </div>
)}



{/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
  <div style={{ display: 'flex', alignItems: 'center', color: "#fff" }}>
    <span style={{ marginRight: '10px', fontWeight: 'bold', fontSize: '15px' }}>Remove Watermark</span>
    <IoDiamond style={{ marginRight: '4px' }} />
    5
  </div>
  <div style={{ position: 'relative' }}>
    <input
      type="checkbox"
      id="watermarkToggle"
      checked={removeWatermark}
      onChange={handleToggleChange}
      style={{ 
        opacity: 0, 
        position: 'absolute', 
        width: 0, 
        height: 0 
      }}
    />
    <label htmlFor="watermarkToggle" style={{ 
      display: 'inline-block', 
      width: '34px', 
      height: '20px', 
      backgroundColor: removeWatermark ? '#007BFF' : '#ccc', 
      borderRadius: '20px', 
      position: 'relative', 
      cursor: 'pointer', 
      transition: 'background-color 0.3s'
    }}>
      <span style={{ 
        display: 'block', 
        width: '20px', 
        height: '20px', 
        backgroundColor: '#fff', 
        borderRadius: '50%', 
        position: 'absolute', 
        top: '0', 
        left: removeWatermark ? '14px' : '0', 
        transition: 'left 0.3s'
      }}></span>
    </label>
  </div>
</div> */}



        
                <div className="input-button-container">
                {selectedMode === 'Manual' ? (
    <div className="manual-mode-inputs">
      <input
        className="prompt-input-bottom custom-input"
        value={memeTextAI}
        onChange={(e) => setMemeTextAI(e.target.value)}
        placeholder="Enter your meme text"
        ref={memeTextRef}
      />
      <input
        className="prompt-input-bottom custom-input"
        value={imageDescriptionAI}
        onChange={(e) => setImageDescriptionAI(e.target.value)}
        placeholder="Description of the meme image"
        ref={imageDescriptionAIRef}
      />
    </div>
  ) : (

    <input
    className="prompt-input-bottom custom-input"
    value={prompt}
    onChange={(e) => setPrompt(e.target.value)}
    placeholder="Describe the meme you want to be generated"
    ref={inputRef}
  />
  )}
                
                </div>
                {(loading || loadingImage) && (
  <div className="loading-bar-wrapper">
    <div className="loading-bar-container">
      <div className="loading-bar" style={{ width: `${progress}%` }}></div>
    </div>
  </div>
)}

                <div className="edit-button-container">
                <button
  className="edit-button"
  onClick={handleEditImage}
  disabled={true} // Force disable the button
>
  {loading ? 'Generating...' : 'Generate New Meme'}
  <FaMagic style={{ marginLeft: "7px" }} />
</button>

  </div>
  <div className="edit-button-container">
  {image2&& selectedMode === "AI" && (
    <button
      className="edit-button-image"
      onClick={handleChangeImage}
      disabled={!image2||loading||
        loadingImage}
    >
      {loadingImage ? 'Changing...' : 'Change Image'}
      <LuRefreshCcw style={{ marginLeft: "7px" }} />
    </button>
  )}
  </div>
    

  </div >
                
              </div>
            </div>
          </div>
        </div>
      </div>

     { isVisible && (
      <button 
        onClick={scrollToTop} 
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          width:"40px",
          height:"40px",
          backgroundColor: '#000000',
          color: '#fff',
          border: 'none',
          borderRadius: '50%',
          padding: '10px',
          cursor: 'pointer',
          zIndex: '1000',
          opacity:"60%"
        }}
      >
        <FaArrowUp />
      </button>)}
      <MemeFeed setShowFooter={setShowFooter} />
    
      {isPopupOpen && (
        <Popup togglePopup={togglePopup}  updatePrice={updatePrice}/>
      )}

      {isPopupAdOpen && credits<=0 && (
        <PopUpAd togglePopupAd={togglePopupAd}  updatePrice={updatePrice} progress={progress} loading={loading} memeTextPopUp={memeTextPopUp} imagePopUp={imagePopUp}/>
      )}

    
    
    </div>
  );
}

export default ImageEditor;